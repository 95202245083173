<template>

  <div class="column">

    <div class="columns">

      <div class="column
                  is-flex
                  is-flex-direction-column
                  is-justify-content-center">

        <div>
          <vue-qr :bgSrc="backgroundFile"
                  :logoSrc="logoFile"
                  :logoMargin="5"
                  :logoCornerRadius="10"
                  :autoColor="true"
                  :text="url"
                  :size="size"
                  :whiteMargin="false"
                  :dotScale="0.35"
                  :colorDark="'#000'"
                  :colorLight="'#fff'"
                  :maskedDots="false"
                  :margin="0"
                  :backgroundDimming="'rgba(0,0,0,0)'"
                  :binarize="false"
                  qid="qId"
                  :callback="onDecode"
                  class="vue-qr" />
        </div>

      </div>

      <div class="column
                  is-flex
                  is-flex-direction-column
                  is-justify-content-flex-start
                  is-align-items-flex-start">

        <div v-if="!isMobile" class="mb-5" style="width: 100%;">
          <p class="is-size-5">
            Construire le QR Code
          </p>
        </div>

        <div class="mb-4" style="width: 100%;">
          <b-field class="mb-2">
            <b-upload v-model="file"
                      accept="image/jpeg, image/png"
                      native
                      @input="handleFileUpload($event, 'backgroundFile')">
              <a class="button is-primary is-fullwidth">
                <b-icon icon="image-search-outline" class="mr-3" />
                <span>Choisir une image de fond</span>
              </a>
            </b-upload>
          </b-field>

          <p class="has-text-grey">
            {{ (file && file.name) || "Pas de fichier séléctionné" }}
          </p>
        </div>

        <div class="mb-6" style="width: 100%;">
          <b-field class="mb-2">
            <b-upload v-model="file2"
                      accept="image/jpeg, image/png"
                      native
                      @input="handleFileUpload($event, 'logoFile')">
              <a class="button is-primary is-fullwidth">
                <b-icon icon="image-search-outline" class="mr-3" />
                <span>Choisir un logo central</span>
              </a>
            </b-upload>
          </b-field>

          <p class="has-text-grey mb-4">
            {{ (file2 && file2.name) || "Pas de fichier séléctionné" }}
          </p>

          <div class="is-flex">
            <div class="is-flex is-justify-content-center is-align-items-center px-2">
              <b-icon icon="lightbulb-on-outline" type="is-warning" />
            </div>
            <div class="has-text-justified">
              <p class="has-text-grey-light">
                Pour le logo, mettre une image de petite dimension permet de
                conserver une meilleur qualité d'affichage.
              </p>
            </div>
          </div>

        </div>

        <b-field label="Taille en pixel">
            <b-numberinput min="200"
                           step="20"
                           v-model="size" />
        </b-field>

        <b-field label="Nombre de QR code par impression">
          <b-numberinput min="1" v-model="nbPrint" />
        </b-field>

        <div class="is-flex
                    is-flex-wrap-wrap
                    is-justify-content-flex-start
                    is-align-items-center
                    mt-6"
             :class="{ 'mb-6': isMobile }">
          <b-button type="is-secondary"
                    outlined
                    icon-left="export"
                    @click="exportImage"
                    :class="{ 'mr-3': !isMobile, 'mb-3': isMobile, }"
                    :expanded="isMobile">
            Export PNG
          </b-button>
          <b-button type="is-secondary"
                    outlined
                    icon-left="printer"
                    @click="print"
                    :class="{ 'mr-3': !isMobile, 'mb-3': isMobile, }"
                    :expanded="isMobile">
            Imprimer
          </b-button>
          <b-button type="is-light"
                    icon-left="refresh"
                    @click="reset"
                    :expanded="isMobile">
            Réinitialiser
          </b-button>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
import VueQr from 'vue-qr';
import printJS from 'print-js';
import download from 'downloadjs';

export default {
  props: {
    isMobile: {
      type: Boolean,
    },
  },
  components: {
    VueQr,
  },
  data: () => ({
    isQRcodeDisplayed: false,
    backgroundFile: undefined,
    logoFile: undefined,
    size: 500,
    nbPrint: 1,
    dotSize: '',
    file: null,
    file2: null,
    dataUrl: null,
  }),
  methods: {
    handleFileUpload(file, fileName) {
      // eslint-disable-next-line prefer-destructuring
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        this[fileName] = reader.result;
      });
      if (file) {
        if (/\.(jpe?g|png)$/i.test(file.name)) {
          reader.readAsDataURL(file);
        }
      }
    },
    reset() {
      this.backgroundFile = undefined;
      this.logoFile = undefined;
      this.size = 500;
      this.file = null;
      this.file2 = null;
      this.dataUrl = null;
    },
    onDecode(dataUrl) {
      this.dataUrl = dataUrl;
    },
    print() {
      const imgSrc = this.dataUrl;
      const printable = [];

      for (let i = 0; i < this.nbPrint; i += 1) {
        printable.push(imgSrc);
      }
      printJS({
        printable,
        type: 'image',
      });
    },
    exportImage() {
      const dataURI = this.dataUrl;

      download(dataURI, 'cookie-menu-qr.png', 'image/png');
    },
  },
  computed: {
    url() {
      const { restaurantId } = this.$route.params;
      return `${process.env.VUE_APP_BASE_URL}/restaurants/${restaurantId}`;
    },
  },
};
</script>